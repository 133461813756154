import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { loadStripe } from "@stripe/stripe-js";
import api from "../../../helpers/Api";
import { AppLoadingGif } from "../../../components/AppLoadingGif";

const NewUserPurchase = () => {
    const location = useLocation();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

    const handleCheckout = async (productId) => {
        const stripe = await stripePromise;

        try {
            const response = await api.post(
                `f/create-anonymous-stripe-checkout-session/${productId}${window.location.search}`
            );

            const session = response.data;

            const { error } = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (error) {
                console.error(error);
            }
        } catch (e) {
            console.error(e);
        }
    };

    /**
     * Obtém o parâmetro "stid" da URL, que contem o id da tabela de preços do Stripe.
     */
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const productId = searchParams.get("spid");
        if (!productId) window.location.replace("/error/404");
        handleCheckout(productId);
    }, [location]);

    return (
        <div className="flex justify-center items-center h-screen">
            <AppLoadingGif />
        </div>
    );
};

export { NewUserPurchase };
